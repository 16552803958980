var render = function render(){var _vm=this,_c=_vm._self._c;return _c('HXContentCard',{attrs:{"title":"Nueva vacante","toBack":{ name: 'AdministradorDeVacantes' }}},[_c('BVForm',{staticClass:"p-4",attrs:{"schema":_vm.nuevaVacante,"callBackSubmit":_vm.crearVacante,"observer":[
      'paisId',
      'departamentoId',
      'sucursalId',
      'departamentoIdEmpresa',
    ]},on:{"watcher":_vm.watcherForm},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between mb-3 pt-2"},[(_vm.cargo == null)?_c('div',{staticClass:"btn btn-success",on:{"click":_vm.openModal}},[_c('i',{staticClass:"fas fa-down-left-and-up-right-to-center"}),_vm._v(" Relacionar con cargo existente ")]):_c('div',{staticClass:"btn btn-danger",on:{"click":_vm.cancelarRelacion}},[_c('i',{staticClass:"fas fa-times"}),_vm._v(" Cancelar relación ")])])]},proxy:true},{key:"text-submit",fn:function(){return [_vm._v(" Crear vacante ")]},proxy:true}])}),_c('b-modal',{staticClass:"modal-sq",attrs:{"id":"modal-relacionar-cargo-vacante","size":"lg","hide-footer":"","hide-header":""},scopedSlots:_vm._u([{key:"default",fn:function({ close }){return [_c('div',{staticClass:"px-3 py-2"},[_c('div',{staticClass:"d-flex justify-content-between mb-3",staticStyle:{"width":"100%"}},[_c('p',{staticClass:"mb-0 h4"},[_c('strong')]),_c('div',{staticClass:"btn btn-danger btn-sm btn-pm",on:{"click":function($event){return close()}}},[_c('i',{staticClass:"fas fa-times"})])]),_c('div',[_c('BVForm',{attrs:{"title":"Seleccione el cargo que desea relacionar con la vacante","schema":[
              {
                name: 'cargoId',
                type: 'select',
                label: 'Cargos',
                options: _vm.cargos,
              },
            ],"callBackSubmit":_vm.relacionarCargo}})],1)])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }